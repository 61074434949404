import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "delete-a-content-item-folder"
    }}>{`Delete a Content Item Folder`}</h1>
    <hr></hr>
    <p>{`To delete a Content Item from the Content Management System, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Content section from the left side menu options. `}</li>
      <li parentName="ol">{`Select 'Delete' next to the Content Item you wish to remove from the system. `}</li>
      <li parentName="ol">{`Confirm that you would like to delete this item by selecting 'Delete'. `}</li>
      <li parentName="ol">{`If you don't wish to delete the item, select 'Cancel'.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      